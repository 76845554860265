import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

import {
  abbreviateLargeNumberForDisplay,
  formatNumber,
} from 'javascript/utils/utils';


export default class extends ApplicationController {

  static targets = [
    'attendanceInput',
    'attendanceInputValue',
    'spendPerCustomerInput',
    'spendPerCustomerInputValue',
    'outputRevenueBoost',
    'outputRevenueBoostSuffix',
    'outputTimeSavedInQueues',
  ];

  onAttendanceChanged(e) {
    this.attendanceInputValue = e.currentTarget.value;
    this.updateOutputs();
  }

  onSpendPerCustomerChanged(e) {
    this.spendPerCustomerInputValue = e.currentTarget.value;
    this.updateOutputs();
  }

  @bound
  calculateRevenueAndTimeSavedInQueues() {
    const rawRevenueBoostPercentage = ((this.attendanceInputValue / 500) + 10);
    const maximumRevenueBoostPercentage = 30;
    const probableRevenueBoostPercentage = Math.min(rawRevenueBoostPercentage, maximumRevenueBoostPercentage);
    const revenueBoostAsMajorCurrencyDenomination = this.attendanceInputValue * this.spendPerCustomerInputValue * (probableRevenueBoostPercentage / 100);

    const spendPerCustomerPerQueuing = 80;
    const timeSavedPerCustomerPerQueuingAsMinutes = 3;
    const timeSavedInQueuesAsMinutes = (this.spendPerCustomerInputValue / spendPerCustomerPerQueuing) * timeSavedPerCustomerPerQueuingAsMinutes * this.attendanceInputValue;

    return new Promise((resolve) => {
      resolve({
        revenueBoostAsMajorCurrencyDenomination: revenueBoostAsMajorCurrencyDenomination,
        timeSavedInQueuesAsMinutes: timeSavedInQueuesAsMinutes,
      });
    });
  }

  @bound
  updateOutputs() {
    this.attendanceInputValueTarget.innerHTML = formatNumber(this.attendanceInputValue);
    this.spendPerCustomerInputValueTarget.innerHTML = formatNumber(this.spendPerCustomerInputValue);

    this.calculateRevenueAndTimeSavedInQueues()
      .then((result) => {
        let revenueBoostDisplayValue = abbreviateLargeNumberForDisplay(result.revenueBoostAsMajorCurrencyDenomination);
        if (result.revenueBoostAsMajorCurrencyDenomination > 1000000) {
          revenueBoostDisplayValue = abbreviateLargeNumberForDisplay(result.revenueBoostAsMajorCurrencyDenomination, 1);
        }
        this.outputRevenueBoostTarget.innerHTML = revenueBoostDisplayValue.value;
        this.outputRevenueBoostSuffixTarget.innerHTML = revenueBoostDisplayValue.unit;

        this.outputTimeSavedInQueuesTarget.innerHTML = formatNumber(Math.round(result.timeSavedInQueuesAsMinutes / 60));
      });
  }

  connect() {
    this.attendanceInputValue = this.attendanceInputTarget.value;
    this.spendPerCustomerInputValue = this.spendPerCustomerInputTarget.value;

    this.updateOutputs();
  }

}
