import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  toggleSidebarShouldShow() {
    this.updateDOM(this.getSidebarElementShouldShowValue() !== "true");
  }

  getBodyElement() {
    return window.document.querySelector("body");
  }

  getSidebarElementShouldShowValue() {
    return this.getBodyElement().getAttribute("data-page-sidebar-should-show") || "false";
  }

  updateDOM(sidebarShouldShow) {
    this.getBodyElement().setAttribute("data-page-sidebar-should-show", sidebarShouldShow);
  }

  onClick() {
    this.toggleSidebarShouldShow();
  }

  connect() {
    this.updateDOM(this.getSidebarElementShouldShowValue());
  }

}
