import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["count", "output", "error"]

  connect() {
    this.firstPageCharacterLimit = 160;
    this.remainingPagesCharacterLimit = 153;
    this.maxCharsPerLinkedSMS = 918;

    this.replaceHTMLOutput();
  }

  countCharacters() {
    this.replaceHTMLOutput();
  }

  replaceHTMLOutput() {
    this.hideWarning();
    this.outputTarget.innerHTML = this.generatePageLimitMessage();

    if (this.countTarget.value.length >= this.maxCharsPerLinkedSMS) {
      this.showWarning();
    }
  }

  generatePageLimitMessage() {
    const pageRanges = [0,160,306,459,612,765,918];

    let charactersPerPageCalculatedValue = 160;
    let characterCount = parseInt(this.countTarget.value.length);
    let currentPageNumber = 0;

    pageRanges.forEach((element, index) => {
      if (this.inRange(characterCount, pageRanges[index], pageRanges[index+1])) {
        currentPageNumber = index + 1;
        charactersPerPageCalculatedValue = pageRanges[index+1];
      }
    });

    return characterCount + " / " + charactersPerPageCalculatedValue + " ( " + currentPageNumber + " Messages )";
  }

  showWarning() {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.remove("util-hidden");
      this.errorTarget.innerText = "You may send a maximum of " + this.maxCharsPerLinkedSMS + " characters!"
    }
  }

  hideWarning() {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.add("util-hidden");
      this.errorTarget.innerText = ""
    }
  }

  inRange(value, rangeMin, rangeMax) {
    return ((value-(rangeMin+1))*(value-(rangeMax+1)) <= 0);
  }
}