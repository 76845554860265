import ApplicationController from "./../application_controller";

export default class extends ApplicationController {
  static targets = [
    'container',
    'item',
  ]

  onSwitchChanged(e) {
    this.containerTarget.style.left = -(e.detail.selectedIndex * 100) + '%';

    if (!this.isInitialized) {
      Array.prototype.forEach.call(this.itemTargets, (item) => {
        item.style.opacity = '1';
        item.style.width = (100 / e.detail.totalItems) + '%';
      });
    }

    this.isInitialized = true;
  }

  connect() {
    this.isInitialized = false;

    this.containerTarget.style.width = (this.itemTargets.length * 100) + '%';

    Array.prototype.forEach.call(this.itemTargets, (item) => {
      item.style.opacity = '0';
    });
  }
}
