
import ShowHideController from "javascript/controllers/shared/show_hide_controller"

export default class extends ShowHideController {

  shouldShow() {
    const startDateDay = document.getElementById('customer_contact_query_start_date_3i').value;
    const startDateMonth = document.getElementById('customer_contact_query_start_date_2i').value;
    const startDateYear = document.getElementById('customer_contact_query_start_date_1i').value;

    const startDate = new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`)

    const endDateDay = document.getElementById('customer_contact_query_end_date_3i').value;
    const endDateMonth = document.getElementById('customer_contact_query_end_date_2i').value;
    const endDateYear = document.getElementById('customer_contact_query_end_date_1i').value;

    const endDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`)

    return startDate < endDate
  }
}
