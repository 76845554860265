import ApplicationController from 'javascript/controllers/application_controller';

import BANKS from 'javascript/utils/bank_config';

const FORM_TARGETS = ['bankName', 'accountNumber', 'branchCode', 'accountType', 'accountHolder'];

export default class extends ApplicationController {
  static targets = ['skipBankAccount', ...FORM_TARGETS];

  get skipBankAccount() {
    return this.skipBankAccountTarget.checked;
  }

  get bankName() {
    return this.bankNameTarget.value;
  }

  get branchCode() {
    return this.branchCodeTarget.value;
  }

  set branchCode(value) {
    this.branchCodeTarget.value = value;
  }

  get defaultBranchCode() {
    if (!this.bankName) return null;
    return BANKS[this.bankName].branch;
  }

  connect() {
    this.toggleForm();
    this.onBankChange();
  }

  toggleForm() {
    FORM_TARGETS.forEach(t => {
      this.targets.find(t).disabled = this.skipBankAccount;
    });
  }

  onBankChange() {
    if (!this.branchCode || Object.values(BANKS).map(x => x.branch).includes(this.branchCode)) {
      this.branchCode = this.defaultBranchCode;
    }
  }
}
