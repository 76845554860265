import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['primaryCategoryContainer', 'radioButtons'];

  get currentlySelectedCategory() {
    return this.primaryCategoryContainerTarget.querySelector('input[type="radio"]:checked');
  }

  get currentlySelectedCategoryId() {
    if (this.currentlySelectedCategory) {
      return parseInt(this.currentlySelectedCategory.value, 10);
    }
  }

  get subCategoryContainer() {
    if (this.currentlySelectedCategory) {
      return this.element.querySelector(`[data-sub-categories-for-category-id='${this.currentlySelectedCategoryId}']`);
    }
  }

  get currentlyOpenSubCategoryContainer() {
    return this.element.querySelector(`[data-sub-categories-for-category-id]:not(.util-hidden)`);
  }

  connect() {
    if (this.currentlySelectedCategory) {
      this.openSubCategories();
    }
  }

  openSubCategories() {
    this.primaryCategoryContainerTarget.classList.add('util-hidden');
    this.subCategoryContainer.classList.remove('util-hidden');
  }

  closeSubCategories() {
    if (this.currentlySelectedCategory) {
      this.currentlySelectedCategory.checked = false;
    }

    this.primaryCategoryContainerTarget.classList.remove('util-hidden');
    this.currentlyOpenSubCategoryContainer.classList.add('util-hidden');

    [].forEach.call(this.element.querySelectorAll('input[type=checkbox]'), checkbox => {
      checkbox.checked = false
    });
  }
}