import ApplicationController from "javascript/controllers/application_controller";
import AutoComplete from "javascript/vendor/pixabay/javascript_autocomplete/auto-complete";


function autoCompleteSource(term, response) {
  window.fetch('/venues?q=' + term, {
    credentials: 'include'
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      json = json.map((item) => {
        return {
          label: item.name,
          address: item.address
        };
      });
      response(json);
    });
}

export default class extends ApplicationController {

  initAutocomplete() {
    this.autoComplete = new AutoComplete({
      selector: this.element,
      minChars: 1,
      source: autoCompleteSource,

      renderItem: function (item, search) {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
        return '<div class="autocomplete-suggestion" data-address="' + item.address + '" data-val="' + item.label + '"><span class="autocomplete-suggestion-query">' + item.label.replace(re, "<strong>$1</strong>") + '</span> <span>' + item.address + '</span></div>';
      },

      onSelect: (event, term, item) => {
        if (this.addressInputElement) {
          this.addressInputElement.value = item.getAttribute('data-address');
        }
      }
    });
  }

  connect() {
    const addressInputElementSelector = this.data.get('addressInputElementSelector');

    this.addressInputElement = addressInputElementSelector ? window.document.querySelector(addressInputElementSelector) : null;

    this.initAutocomplete();
  }

}
