import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { scrollWindowToTop } from "javascript/utils/utils";

export default class extends ApplicationController {

  static targets = [
    'errorsPanel',
  ];

  close() {
    this.errorsPanelTarget.classList.add('event_edit_publish_errors--invisible');
    this.errorsPanelTarget.addEventListener('transitionend', this.onTransitionEndHandler);
  }

  @bound
  onTransitionEndHandler() {
    this.errorsPanelTarget.classList.add('event_edit_publish_errors--hidden');
    this.errorsPanelTarget.removeEventListener('transitionend', this.onTransitionEndHandler);
  }

  connect() {
    scrollWindowToTop(500);
  }
}
