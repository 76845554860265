import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  @bound
  onTurbolinksLoad() {
    this.element.classList.remove(this.shouldShowClassName);
  }

  @bound
  onTurbolinksVisit() {
    this.element.classList.add(this.shouldShowClassName);
  }

  connect() {
    this.shouldShowClassName = 'turbolinks-loading-overlay--show';
    this.window.document.addEventListener('turbolinks:load', this.onTurbolinksLoad);
    this.window.document.addEventListener('turbolinks:visit', this.onTurbolinksVisit);
  }

  disconnect() {
    this.window.document.removeEventListener('turbolinks:load', this.onTurbolinksLoad);
    this.window.document.removeEventListener('turbolinks:visit', this.onTurbolinksVisit);
  }

}