import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'currencySwitcher',
    'currencyInput',
  ];

  @bound
  onChange(e) {
    let symbol = e.currentTarget.value;
    const selectedOption = e.currentTarget.options[e.currentTarget.selectedIndex];

    if (selectedOption.getAttribute('data-symbol')) {
      symbol = selectedOption.getAttribute('data-symbol');
    }

    if (!symbol) {
      symbol = '';
    }

    this.currencyInputTargets.forEach((currencyInput) => {
      currencyInput.setAttribute('data-currency-input-symbol', symbol);
    });
  }

  connect() {
    setTimeout(() => {
      this.currencySwitcherTargets.forEach((currencySwitcher) => {
        this.onChange({
          currentTarget: currencySwitcher,
        });
      });
    }, 0);
  }

}
