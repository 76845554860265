import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  initAutocomplete() {
    const boundsInZA = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(-30.5595, 22.9375)
    );

    const options = {
      bounds: boundsInZA
    };

    const autocompleteElement = new window.google.maps.places.Autocomplete(this.element, options);

    autocompleteElement.setFields([
      'place_id'
    ]);
  }

  @bound
  onGoogleMapsAPILoad() {
    this.initAutocomplete();
  }

  connect() {
    if (window.howler && (window.howler.google_maps_api_load)) {
      this.onGoogleMapsAPILoad();
    } else {
      window.document.addEventListener('howler:google_maps_api_load', this.onGoogleMapsAPILoad);
    }
  }

  disconnect() {
    window.document.removeEventListener('howler:google_maps_api_load', this.onGoogleMapsAPILoad);
  }

}
