import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller"
import Cleave from 'cleave.js'

export default class extends ApplicationController {
  static targets = ['timeInput', 'hour', 'minute'];

  @bound
  updateValueAndEmitChangeEvent() {
    this.updateValuesFromDOMNodes();

    this.element.dispatchEvent(new window.Event('change', { 'bubbles': true }));
  }

  @bound
  onDateInputChange(e) {
    e.stopPropagation();
    this.updateValueAndEmitChangeEvent();
  }

  @bound
  onTimeChange(e) {
    try {
      e.stopPropagation();
    } catch (error) { }

    let [hour, minute] = e.target.placeholder.split(':');

    if (e.target.value != "") {
      [hour, minute] = e.target.value.split(':');
    }

    this.hourTarget.value = hour;
    this.minuteTarget.value = minute;

    this.updateValueAndEmitChangeEvent();
  }

  @bound
  updateValuesFromDOMNodes() {
    this.yearValue = this.yearSelect.value;
    this.monthValue = parseInt(this.monthSelect.value, 10) - 1;
    this.dayValue = this.daySelect.value;
    this.hourValue = this.hourTarget.value;
    this.minuteValue = this.minuteTarget.value;

    const value = new Date(this.yearValue, this.monthValue, this.dayValue, this.hourValue, this.minuteValue);
    try {
      this.element.value = value.toISOString();
    } catch (e) {
      this.element.value = '';
    }
  }

  connect() {
    const dateSelects = this.element.querySelectorAll('.date-time__select');

    this.yearSelect = this.element.querySelector('[id$="_1i"]');
    this.monthSelect = this.element.querySelector('[id$="_2i"]');
    this.daySelect = this.element.querySelector('[id$="_3i"]');

    new Cleave(this.timeInputTarget, {
      time: true,
      timePattern: ['h', 'm']
    });

    Array.prototype.forEach.call(dateSelects, (dateSelect) => {
      const selectChangeEvent = new window.Event('change', { bubbles: 'true' });
      dateSelect.addEventListener('change', this.onDateInputChange);
      dateSelect.dispatchEvent(selectChangeEvent);
    });

    this.onTimeChange({
      target: {
        value: this.timeInputTarget.value,
        placeholder: this.timeInputTarget.placeholder
      }
    });
  }
}