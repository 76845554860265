import ShowHideController from "javascript/controllers/shared/show_hide_controller"

export default class extends ShowHideController {

  shouldShow(thing) {
    if (thing.dataset.showCityValue !== undefined) {
      if (this.inputValue.length === 0) {
        return false
      }
      return thing.dataset.showCityValue !== this.inputValue;
    }
    if (thing.dataset.showValue !== undefined) {
      return thing.dataset.showValue === this.inputValue;
    }
    if (thing.dataset.hideValue !== undefined) {
      return thing.dataset.hideValue !== this.inputValue;
    }
  }
}
