import ApplicationController from "javascript/controllers/application_controller";


export default class extends ApplicationController {

  static targets = [
    'publishButton',
  ];

  get organiserId() {
    return this.data.get('organiserId');
  }

  get eventId() {
    return this.data.get('eventId');
  }

  isEventPublishable() {
    return window.fetch('/organisers/' + this.organiserId + '/events/' + this.eventId + '/publishable', {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
    })
      .then((res) => {
        return res.json();
      })
      .then(function (json) {
        return JSON.stringify(json);
      })
      .catch(function () {
        return JSON.stringify(false);
      });
  }

  updatePublishButtonState() {
    try {
      this.publishButtonTarget.setAttribute('disabled', 'disabled');

      this.isEventPublishable()
        .then((result) => {
          if (result === 'true') {
            this.enablePublishButton();
          } else {
            this.disablePublishButton();
          }
        });
    } catch (e) { }
  }

  disablePublishButton() {
    this.publishButtonTarget.setAttribute('disabled', 'disabled');
  }

  enablePublishButton() {
    this.publishButtonTarget.removeAttribute('disabled');
  }

  onWindowResize() {
    const eventHeaderDOMNodePaddingBottom = parseInt(window.getComputedStyle(this.eventHeaderDOMNode, null).getPropertyValue('padding-bottom'), 10);

    if (window.innerWidth < 720) {
      this.eventHeaderDOMNode.style.top = ((this.pageHeaderDOMNode.offsetHeight + this.eventHeaderButtonsDOMNode.offsetHeight + eventHeaderDOMNodePaddingBottom) - this.eventHeaderDOMNode.offsetHeight) + 'px';
    } else {
      this.eventHeaderDOMNode.style.top = '0px';
    }

    this.eventDetailHeaderDOMNodes.forEach((item) => {
      if (window.innerWidth < 720) {
        item.style.top = (this.pageHeaderDOMNode.offsetHeight + this.eventHeaderButtonsDOMNode.offsetHeight + eventHeaderDOMNodePaddingBottom) + 'px';
      } else {
        item.style.top = (this.eventHeaderDOMNode.offsetHeight) + 'px';
      }
    });
  }

  onEventDataChanged() {
    this.updatePublishButtonState();
  }

  connect() {
    this.pageHeaderDOMNode = window.document.querySelector('.page__header');
    this.eventHeaderDOMNode = window.document.querySelector('.events-show-header');
    this.eventDetailHeaderDOMNodes = window.document.querySelectorAll('.event-details-panel__header');
    this.eventHeaderDetailsDOMNode = window.document.querySelector('.events-show-header__details');
    this.eventHeaderButtonsDOMNode = window.document.querySelector('.events-show-header__buttons');

    this.onWindowResize(null);

    this.updatePublishButtonState();

    this.attributeObserver = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-event-page-event-data-changed')) {
        this.onEventDataChanged();
      }
    });

    this.attributeObserver.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.attributeObserver.disconnect();
  }

}
