import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'symbol',
  ];

  @bound
  updateSymbol(symbol) {
    this.symbolTarget.innerHTML = symbol;
  }

  connect() {
    this.updateSymbol(this.data.get('symbol'));

    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-currency-input-symbol')) {
        this.updateSymbol(this.data.get('symbol'));
      }
    });

    this.observer.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

}
