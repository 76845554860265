import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'javascript/analytics/sentry';

import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

import 'javascript/compatibility/polyfills';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import 'javascript/analytics/google_analytics_tracking';

Turbolinks.start();
Rails.start();

// Allows use to access Rails in app/views/organiser_portal/_refresh_csrf_token.slim
global.Rails = Rails;

const application = Application.start();

const sharedContext = require.context("javascript/controllers/shared", true, /.js$/);
application.load(definitionsFromContext(sharedContext));

const context = require.context("javascript/controllers/organiser_portal", true, /.js$/);
application.load(definitionsFromContext(context));
