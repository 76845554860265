import ApplicationController from "javascript/controllers/application_controller";


export default class extends ApplicationController {

  static targets = [
    'submitButton',
    'required'
  ];

  isFormValid() {
    return this.requiredTargets.filter((item) => {
      return item.type === 'checkbox' ? !item.checked : (item.value.split(' ').join('') === '');
    }).length === 0;
  }

  updateFormSubmissionAllowed() {
    this.submitButtonTarget.disabled = this.isFormValid() ? false : true;
  }

  onInputTargetChange() {
    this.updateFormSubmissionAllowed();
  }

  connect() {
    this.updateFormSubmissionAllowed();
  }

}
