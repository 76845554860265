import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  showButtonLoader() {
    this.element.classList.add('button--loading')
  };

  connect(){
    this.element.classList.remove('button--loading')
  };
}