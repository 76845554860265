import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

function getFirstTextChild(element) {
  return [].find.call(element.childNodes, x => x.nodeName === '#text');
}

function getFirstTextChildContent(element) {
  let textNode = getFirstTextChild(element);
  if (textNode) {
    return textNode.textContent;
  }
  return '';
}

function findOrCreateFirstTextChild(element, text = '') {
  let textNode = getFirstTextChild(element);
  if (!textNode) {
    textNode = element.ownerDocument.createTextNode(text);
    element.appendChild(textNode);
  } else {
    textNode.textContent = text;
  }
  return textNode;
}

export default class extends ApplicationController {
  static targets = ['source', 'destination'];

  connect() {
    this.observer = new MutationObserver(mutationList => {
      let shouldUpdate = false;
      if (this.hasDestinationTarget) {
        for (let mutation of mutationList) {
          if (mutation.target.parentElement !== this.destinationTarget) {
            shouldUpdate = true;
            break;
          }
        }
      }
      if (shouldUpdate) {
        this.updateDestination();
      }
    });

    this.observer.observe(this.element, { attributes: false, childList: true, characterData: true, subtree: true });

    this.updateDestination();
  }

  disconnect() {
    this.observer.disconnect();
  }

  @bound
  updateDestination() {
    let textContent = '';
    if (this.hasSourceTarget) {
      textContent = getFirstTextChildContent(this.sourceTarget);
    }
    findOrCreateFirstTextChild(this.destinationTarget, textContent);
  }
}