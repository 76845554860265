import bound from 'bound-decorator';
import Rails from '@rails/ujs';
import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'form',
    'jsonDatasource',
    'htmlDatasource',
    'testEmailForm',
    'testHtmlDatasource',
    'testEmailRecipient',
  ]

  @bound
  initPlugin() {
    const options = {
      id: '#' + this.element.id,
      authorize: {
        apiKey: this.data.get('api-key'),
        userId: this.data.get('user-id'),
      },
      templateId: this.data.get('template-id'),
      sendTestEmail: true,
      callbacks: {
        onSaveAndClose: (json, html) => {
          this.data.set('should-redirect', 'true')
          this.saveEmailContent(json, html);
        },
        onSave: (json, html) => {
          this.data.set('should-redirect', 'false')
          this.saveEmailContent(json, html);
        },
        onTestSend: (email, json, html) => {
          this.sendTestEmail({ email, html })
        },
        onInit: () => {
          setTimeout(() => {
            if (this.jsonDatasourceTarget.innerText.length > 0) {
              this.window.TopolPlugin.load(Buffer.from(this.jsonDatasourceTarget.innerText, 'base64').toString());
            }
          });
        }
      }
    };

    this.window.TopolPlugin.init(options);
  }

  addScriptElement(callback) {
    const scriptElement = this.window.document.createElement('script');
    scriptElement.addEventListener('load', callback);
    scriptElement.src = 'https://d5aoblv5p04cg.cloudfront.net/editor-3/loader/build.js';
    this.window.document.body.appendChild(scriptElement);
  }

  updateHeight() {
    this.element.style.height = '100%';
  }

  saveEmailContent(json, html) {
    this.htmlDatasourceTarget.innerHTML = html;
    this.jsonDatasourceTarget.innerText = Buffer.from(JSON.stringify(json)).toString('base64');
    Rails.fire(this.formTarget, 'submit');
  }

  redirect() {
    if (this.data.get('should-redirect') === 'true') {
      window.location.replace(this.data.get('redirect-url'));
    }
  }

  sendTestEmail({ email, html }) {
    this.testEmailRecipientTarget.value = email;
    this.testHtmlDatasourceTarget.innerHTML = html;
    Rails.fire(this.testEmailFormTarget, 'submit');
  }

  testEmailSuccess() {
    alert('Test Email Sent');
  }

  connect() {
    this.updateHeight();

    if (this.window.TopolPlugin) {
      this.initPlugin();
    } else {
      this.addScriptElement(this.initPlugin);
    }
  }

}
