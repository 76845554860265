import bound from 'bound-decorator';
import { findColourBetween } from 'javascript/utils/utils';
import ColourPreviewController from 'javascript/controllers/organiser_portal/colour_preview_controller';
export default class extends ColourPreviewController {

  static targets = [
    'labelInput',
    'textColourInput',
  ];

  @bound
  updateOutput() {
    super.updateOutput();
    this.previewTarget.style.color = `${this.textColourInputTarget.value}`;
    this.previewTarget.innerText = this.labelInputTarget.value;
  }

  @bound
  onColourInputChanged() {
    this.updateOutput();
  }

  @bound
  onLabelInputUpdated() {
    this.updateOutput();
  }

  connect() {
    super.connect()
    this.labelInputTarget.addEventListener('input', this.onLabelInputUpdated);
    this.textColourInputTarget.addEventListener('change', this.onColourInputChanged);
  }

}
