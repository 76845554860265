import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  static targets = [
    'iframe',
    'loadingIndicator',
  ]

  connect() {
    this.iframeTarget.style.display = "none";
    this.loadingIndicatorTarget.style.display = "block";

    this.iframeTarget.onload = () => {
      this.iframeTarget.style.display = "block";
      this.loadingIndicatorTarget.style.display = "none";
    }
  }
}
