import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { findColourBetween } from 'javascript/utils/utils';

export default class extends ApplicationController {

  static targets = [
    'preview',
    'previewStyle',
    'solidColourInput',
    'gradientColourLeftInput',
    'gradientColourRightInput',
    'gradientColourMiddleInput',
    'textColourInput',
    'textColourPreview'
  ];

  @bound
  updateOutput() {
    if (this.previewStyle === 'solid') {
      this.previewTarget.style.background = this.solidColourInputTarget.value;
    } else {
      this.gradientColourMiddleInputTarget.value = findColourBetween(this.gradientColourLeftInputTarget.value, this.gradientColourRightInputTarget.value, 0.5);
      this.previewTarget.style.background = `linear-gradient(to right, ${this.gradientColourLeftInputTarget.value} 0%, ${this.gradientColourRightInputTarget.value} 100%)`;
    }

    if (this.hasTextColourPreviewTarget) {
      this.textColourPreviewTargets.forEach(textPreview => {
        textPreview.style.color = this.textColourInputTarget.value
      })
    }
  }

  @bound
  onStyleChange() {
    this.previewStyle = '';
    this.previewStyleTargets.forEach((previewStyleTargetItem) => {
      if (previewStyleTargetItem.checked) {
        this.previewStyle = previewStyleTargetItem.value;
      }
    });
    this.updateOutput();
  }

  @bound
  onColourInputChanged() {
    this.updateOutput();
  }

  @bound
  onLabelInputUpdated() {
    this.updateOutput();
  }

  connect() {
    this.previewStyle = '';

    this.onStyleChange();
    this.updateOutput();
  }

}
