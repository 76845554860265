import ApplicationController from 'javascript/controllers/application_controller';


function getTimeInSecondsFromAttributeValue(value) {
  let date = null,
    _value = '';

  if (!value) {
    return 0;
  }

  if (value.split("-").length >= 3) {
    date = new Date(value);
  } else {
    _value = parseInt(value, 10);
  }

  return date ? (date.getTime() / 1000) : _value;
}

function getAbsoluteTimeForDisplay(timeInSeconds) {
  let keyName = "",
    suffix = "",
    prefix = "",
    valueExact = 0,
    valueFloored = 0;

  const timeIntervals = {
    week: timeInSeconds / 604800,
    day: timeInSeconds / 86400,
    hour: timeInSeconds / 3600,
    minute: timeInSeconds / 60,
    second: timeInSeconds
  };

  for (keyName in timeIntervals) {
    if (timeIntervals.hasOwnProperty(keyName)) {
      valueExact = timeIntervals[keyName];
      valueFloored = Math.floor(valueExact);

      if (valueExact - valueFloored >= 0.5) {
        valueFloored += 1;
      }

      if (valueFloored === 1 || valueFloored > 1) {
        break;
      }
    }
  }

  prefix = getPrefixForValue(valueExact, valueFloored);
  suffix = getSuffixForValue(keyName, valueFloored);

  if (valueFloored > 0 && valueExact !== valueFloored) {

    if (valueFloored === 1) {
      valueFloored = "a";

      if (keyName === "hour") {
        valueFloored = "an";
      }
    }
  }

  if (keyName === "second") {
    prefix = "";
    valueFloored = "";
    suffix = "";
  }

  return prefix + valueFloored + suffix;
}


function getSuffixForValue(keyName, valueFloored) {
  let suffix = "";

  if (valueFloored > 1) {
    suffix = " " + keyName + "s";
  } else if (valueFloored === 1) {
    suffix = " " + keyName;
  }

  return suffix;
}


function getPrefixForValue(valueExact, valueFloored) {
  let prefix = "";

  if (valueFloored > 0 && valueExact !== valueFloored) {
    prefix = "about ";
  }

  return prefix;
}


export default class extends ApplicationController {

  updateContent() {
    const startTimeInSeconds = getTimeInSecondsFromAttributeValue(this.data.get("start"));
    const endTimeInSeconds = getTimeInSecondsFromAttributeValue(this.data.get("end"));
    const diffTimeInSeconds = (startTimeInSeconds > endTimeInSeconds) ? 0 : endTimeInSeconds - startTimeInSeconds;

    this.element.innerHTML = diffTimeInSeconds > 0 ? getAbsoluteTimeForDisplay(diffTimeInSeconds) : "";
  }

  connect() {
    this.updateContent();

    this.attributeObserver = new MutationObserver(() => {
      this.updateContent();
    });

    this.attributeObserver.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.attributeObserver.disconnect();
  }

}
