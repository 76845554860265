import ApplicationController from 'javascript/controllers/application_controller';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = [
    'fileInput',
    'fileNameOutput',
    'submitInput',
    'errorList',
    'submitButton'
  ];

  get maxFileSize() {
    return this.data.get('maxFileSize');
  }

  removeAllDragClasses() {
    this.element.classList.remove('image-upload-form--window-dragging');
    this.element.classList.remove('image-upload-form--dragenter');
    this.element.classList.remove('image-upload-form--dragenter');
  }

  removeDragOverClass() {
    this.element.classList.remove('image-upload-form--dragenter');
  }

  onWindowDragOver() {
    this.element.classList.add('image-upload-form--window-dragging');
  }

  onWindowDragLeave() {
    this.element.classList.remove('image-upload-form--window-dragging');
  }

  onInputDragEnter() {
    this.element.classList.add('image-upload-form--dragenter');
  }

  onInputDragLeave() {
    this.removeDragOverClass();
  }

  onInputDropOrChange(e) {
    e.preventDefault();
    let files = [];
    this.removeDragOverClass();

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    Array.from(files).forEach(file => this.uploadFile(file));
  }

  onUploadNewImageLinkClick(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  isFileTypeAllowed(file) {
    const acceptableFileTypes = this.fileInputTarget.accept.split(',');
    let isAllowed = false;

    if (acceptableFileTypes.indexOf('*') > -1) {
      isAllowed = true;
    } else if (acceptableFileTypes.indexOf(file.type) > -1) {
      isAllowed = true;
    }

    return isAllowed;
  }

  uploadFile(file) {
    this.errorListTarget.innerHTML = '';
    this.removeAllDragClasses();

    if (!this.isFileTypeAllowed(file)) {
      this.element.classList.add('image-upload-form--upload-error');
      this.fileNameOutputTarget.innerHTML = '';
      this.submitInputTarget.setAttribute("value", "");
      this.errorListTarget.innerHTML = '<li>File type is not allowed</li>';
      return;
    }

    if(this.maxFileSize) {
      if(!this.isFileSizeAcceptable(file)) {
        this.element.classList.add('image-upload-form--upload-error');
        this.fileNameOutputTarget.innerHTML = '';
        this.submitInputTarget.setAttribute("value", "");
        this.errorListTarget.innerHTML = '<li>Maximum file size is '+this.maxFileSize+'mb</li>';
        return;
      }
    }

    const url = this.fileInputTarget.dataset.directUploadUrl;
    const directUpload = new DirectUpload(file, url, this);
    const form = this.element.closest('form');

    this.fileInputTarget.setAttribute('disabled', 'disabled');
    this.progressElement.setAttribute('value', 0);
    this.fileNameOutputTarget.innerHTML = file.name;
    this.element.classList.add('image-upload-form--is-uploading');

    directUpload.create((error, blob) => {
      this.fileInputTarget.setAttribute('disabled', '');
      this.element.classList.remove('image-upload-form--is-uploading');
      if (error) {
        this.element.classList.add('image-upload-form--upload-error');
        this.fileNameOutputTarget.innerHTML = '';
        this.submitInputTarget.setAttribute("value", "");
      } else {
        this.element.classList.add('image-upload-form--upload-success');
        this.submitInputTarget.name = this.fileInputTarget.name;
        this.submitInputTarget.setAttribute("value", blob.signed_id);
        Rails.fire(form, 'submit');
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (e) => {
      this.directUploadDidProgress(e);
    });
  }

  directUploadDidProgress(e) {
    const percent = (e.loaded / e.total) * 100;
    this.progressElement.setAttribute('value', percent ? percent : 0);
  }

  isFileSizeAcceptable(file) {
    let sizeInMB = (file.size / (1024*1024)).toFixed(2);

    if(sizeInMB <= parseInt(this.maxFileSize)) {
      return true;
    }

    return false;
  }

  connect() {
    this.progressElement = this.element.querySelector('.progress');
    this.progressElement.setAttribute('min', 0);
    this.progressElement.setAttribute('max', 100);
    this.progressElement.setAttribute('value', 0);

    this.submitButtonTarget.style = 'display: none;';
  }

}
