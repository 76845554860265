import ApplicationController from "javascript/controllers/application_controller";
import DrawerController from "./drawer_controller";

export default class extends DrawerController {
  static targets = ["content", "checkbox"]

  toggle() {
    if (!this.enabled) return;

    if (this.isOpen) {
      this.close();
      this.checkboxTarget.checked = false;
    } else {
      this.open();
      this.checkboxTarget.checked = true;
    }
  }

  drawerContentErrorObserver() {
    if (this.element.getElementsByClassName('alert__message')[0] === undefined) {
      return;
    } else {
      this.open();
      this.checkboxTarget.checked = true;
    }
  }
}