import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {

  static targets = [
    'filename',
  ];

  onChange(e) {
    const files = e.target.files || [{ name: '' }];
    this.filenameTarget.innerHTML = files[0].name;
  }
}
