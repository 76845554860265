import ApplicationController from "./../application_controller";
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'item',
    'label',
    'backgroundSelectionIndicator',
  ]

  onSelectionIndexChanged(index, totalItems) {
    this.backgroundSelectionIndicatorTarget.style.left = ((index / totalItems) * 100) + '%';

    if (this.hasSetInitialSelectionIndex) {
      this.element.classList.add('has-set-initial-selection-index');
    }

    Rails.fire(this.element, 'howler:switch.change', {
      selectedIndex: index,
      totalItems: totalItems,
    });

    this.hasSetInitialSelectionIndex = true;
  }

  onItemChanged(e) {
    this.onSelectionIndexChanged(Number(e.target.getAttribute('data-switch-index')), this.itemTargets.length);
  }

  connect() {
    const itemWidth = (100 / this.itemTargets.length) + '%';

    this.hasSetInitialSelectionIndex = false;
    this.backgroundSelectionIndicatorTarget.style.width = itemWidth;

    Array.prototype.forEach.call(this.labelTargets, (label) => {
      label.style.width = itemWidth;
    });

    if (Array.prototype.find.call(this.itemTargets, (item) => item.checked)) {
      window.setTimeout(() => {
        Array.prototype.forEach.call(this.itemTargets, (item) => {
          if (item.checked) {
            this.onItemChanged({
              target: item,
            });
          }
        });
      }, 0);
    } else {
      this.onSelectionIndexChanged(-1, this.itemTargets.length);
    }
  }
}