import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['source', 'destination'];

  get styleName() {
    return this.data.get('name');
  }

  connect() {
    this.observer = new MutationObserver(mutationList => {
      let shouldUpdate = false;
      if (this.hasDestinationTarget) {
        for (let mutation of mutationList) {
          if (mutation.type !== 'attributes' || mutation.target !== this.destinationTarget) {
            shouldUpdate = true;
          }
        }
      }
      if (shouldUpdate) {
        this.updateDestination();
      }
    });

    this.observer.observe(this.element, { attributes: true, childList: true, characterData: false, subtree: true });

    this.updateDestination();
  }

  disconnect() {
    this.observer.disconnect();
  }

  @bound
  updateDestination() {
    let styleText = '';
    if (this.hasSourceTarget) {
      styleText = this.sourceTarget.style[this.styleName];
    }
    this.destinationTarget.style[this.styleName] = styleText;
  }
}