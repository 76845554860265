import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'audienceListItem',
    'outputReach',
    'outputCost',
  ];

  onListItemSelectionToggled() {
    this.updateReachAndCostOutputs();
  }

  @bound
  addLoadingStateClass() {
    this.element.classList.add(this.loadingStateClass);
  }

  @bound
  removeLoadingStateClass() {
    this.element.classList.remove(this.loadingStateClass);
  }

  @bound
  updateReachAndCostOutputs() {
    const apiRequestPath = this.buildAPIRequestPath();

    if (apiRequestPath) {
      this.fetchReachAndCost(apiRequestPath)
      .then((result) => {
        this.outputCostTarget.innerHTML = result.cost;
        this.outputReachTarget.innerHTML = parseInt(result.reach) === 1 ? "1 person" : parseInt(result.reach) + " people";
      });
    }
  }

  @bound
  buildAPIRequestPath() {
    if (!this.data.get('apiPath')) {
      return '';
    }

    const apiRequestPath = this.data.get('apiPath');

    const checkedItems = Array.prototype.filter.call(this.audienceListItemTargets, (item) => {
      return item.checked;
    });

    const queryString = checkedItems.map((item) => {
      return 'selected_audience_lists[]=' + item.dataset.id;
    }).join('&');

    return apiRequestPath + (queryString !== '' ? '?' + queryString : '');
  }

  @bound
  fetchReachAndCost(url) {
    this.addLoadingStateClass();
    return window.fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
    })
      .then((res) => {
        this.removeLoadingStateClass();
        return res.json();
      })
      .catch(() => {
        this.removeLoadingStateClass();
        return {
          reach: '0',
          cost: 'R0.00',
        };
      });
  }

  connect() {
    this.loadingStateClass = 'audience-reach-calculator--is-loading';
    this.updateReachAndCostOutputs();
  }

}
