import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { normalizeHexColour } from 'javascript/utils/utils';

export default class extends ApplicationController {

  static targets = [
    'source',
    'colourInput',
    'textInput',
  ];

  @bound
  updateSource(value) {
    this.sourceTarget.value = normalizeHexColour(value);
    const event = new this.window.Event('change', { bubbles: true });
    this.sourceTarget.dispatchEvent(event);
  }

  @bound
  onColourInputChanged() {
    this.textInputTarget.value = this.colourInputTarget.value;
    this.updateSource(this.colourInputTarget.value);
  }

  @bound
  onTextInputChanged() {
    this.colourInputTarget.value = normalizeHexColour(this.textInputTarget.value);
    this.updateSource(this.textInputTarget.value);
  }

  connect() {
    this.textInputTarget.value = this.colourInputTarget.value = this.sourceTarget.value;
    this.colourInputTarget.addEventListener('input', this.onColourInputChanged);
    this.textInputTarget.addEventListener('input', this.onTextInputChanged);
  }

}
