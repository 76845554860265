import ApplicationController from 'javascript/controllers/application_controller';
import Sortable from 'sortablejs';

export default class extends ApplicationController {
  static targets = ['sortableList', 'listItemTemplate', 'itemPosition', 'scrollToEnd'];

  connect() {
    Sortable.create(this.sortableListTarget, {
      chosenClass: "sortable-list__chosen-item",
      ghostClass: "sortable-list__ghost-item",
      handle: ".sortable-list__handle",
      onEnd: evt => {
        this.reOrderPositions();
      }
    });
    this.reOrderPositions();

  }

  addListItem() {
    let templateContent = null;

    if(this.listItemTemplateTarget.nodeName === 'TEMPLATE') {
      templateContent = this.listItemTemplateTarget.content.cloneNode(true);
    }
    else {
      templateContent = this.listItemTemplateTarget.cloneNode(true);
    }

    this.sortableListTarget.appendChild(templateContent);
    this.reOrderPositions();

    const lastChild = this.sortableListTarget.lastChild;

    this.focusOnInput(lastChild);

    if (!this.scrollToEndTarget.scrollIntoView || this.browserIsIOS()) {
      this.element.parentNode.scrollTop += lastChild.scrollHeight;
      return;
    }

    this.scrollToEndTarget.scrollIntoView({ behavior: "smooth" });

    if (this.scrollToEndTarget.scrollIntoViewIfNeeded) {
      this.scrollToEndTarget.scrollIntoViewIfNeeded();
    }
  }

  removeListItem(event) {
    event.target.closest("li").remove();
    this.reOrderPositions();
  }

  focusOnInput(listItem) {
    const listItemInputs = listItem.getElementsByTagName('input');

    if (listItem.firstChild.classList) {
      listItem.firstChild.classList.add('sortable-list__row--focussed');
    }

    for (let i = 0; i < listItemInputs.length; ++i) {
      if (listItemInputs[i].name !== "position") {
        listItemInputs[i].value = "";
      }
    }

    listItemInputs[1].focus();
  }

  onInputBlur(event) {
    event.target.closest(".sortable-list__row").classList.remove('sortable-list__row--focussed');
  }

  onInputFocus(event) {
    event.target.closest(".sortable-list__row").classList.add('sortable-list__row--focussed');
  }

  reOrderPositions() {
    for (let i = 0; i < this.sortableListTarget.getElementsByTagName("li").length; i++) {
      this.itemPositionTargets[i].value = i
    }
  }

  browserIsIOS() {
    const standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      return true;
    }

    return false;
  }
}

