import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';
import bowser from 'bowser';

const EDGE = 20;
const SCROLL_SPEED = bowser.ios ? 2 : 1;

function getPosition(el) {
  const bodyRect = document.body.getBoundingClientRect();
  const { top, left } = el.getBoundingClientRect();

  const marginTop = parseInt(getComputedStyle(el).marginTop, 10);
  const marginLeft = parseInt(getComputedStyle(el).marginLeft, 10);

  return {
    x: (left - bodyRect.left) - marginLeft,
    y: (top - bodyRect.top) - marginTop,
    width: el.offsetWidth,
    height: el.offsetHeight
  };
}

function getCenter(el) {
  const { x, y } = getPosition(el);

  return { x: x + el.offsetWidth / 2, y: y + el.offsetHeight / 2 };
}

export default class extends ApplicationController {
  static targets = ["container", "thing", "category", "subSortie", "form"];

  get topEdge() {
    return EDGE + (this.data.has('topEdge') ? parseInt(this.data.get('topEdge'), 10) : 0);
  }

  get bottomEdge() {
    let edgeAmount = EDGE + (this.data.has('bottomEdge') ? parseInt(this.data.get('bottomEdge'), 10) : 0);
    return window.innerHeight - edgeAmount;
  }

  connect() {
    this.preventScrolling = false;

    window.addEventListener('touchmove', event => {
      if (this.preventScrolling) {
        if (event.cancelable) event.preventDefault();
      }
    }, { passive: false });

    this.thingTargets.forEach(thing => {
      thing.classList.add('sortie-draggable');

      thing.addEventListener('mousedown', event => {
        this.currentThing = thing;
        this.captureOriginalPosition(thing, { x: event.pageX, y: event.pageY });

        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);

        if (event.cancelable) event.preventDefault();
        event.stopPropagation();
      });

      thing.addEventListener('touchstart', event => {

        clearTimeout(this.tapTimeout);
        this.tapTimeout = setTimeout(this.onTouchHold(event, thing), 200);

        window.addEventListener('touchmove', this.onTouchMove, { passive: false });
        window.addEventListener('touchend', this.onTouchEnd);
        window.addEventListener('touchcancel', this.onTouchEnd);
        window.addEventListener('contextmenu', this.onContextMenu);

        event.stopPropagation();
      });
    });

    window.document.addEventListener('scroll', () => {
      this.scrolled = true;
    });

    this.categoryExpandedObserver = new MutationObserver((mutationList) => {
      if (mutationList.find(x => x.attributeName === 'data-drawer-open')) {
        this.onCategoryExpandedStateChanged();
      }
    });

    this.categoryExpandedObserver.observe(this.element, {
      attributes: true,
      subtree: true,
    });
  }

  onCategoryExpandedStateChanged() {
    Array.prototype.forEach.call(this.categoryTargets, (categoryDOMNode) => {
      const categoryID = categoryDOMNode.querySelector('[label="ticket_category"]').value;
      if (categoryID) {
        const isExpanded = (categoryDOMNode.getAttribute('data-drawer-open') === 'true');
        const input = categoryDOMNode.querySelector(`input[name="ticket_categories[${categoryID}][is_collapsed_in_organiser_portal]"]`);
        input.value = !isExpanded;
      }
    });

    this.submitForm();
  }

  startDragging(thing) {
    this.isDragging = true;
    document.body.classList.add('sortie-is-dragging');

    thing.style.top = `${thing.originalPos.y + document.body.getBoundingClientRect().top}px`;
    thing.style.left = `${thing.originalPos.x + document.body.getBoundingClientRect().left}px`;
    thing.style.width = `${thing.offsetWidth}px`;
    thing.classList.add("sortie-moving");
    thing.hasStartedMoving = true;

    const placeholder = document.createElement('div');
    placeholder.classList.add('sortie-placeholder');
    placeholder.style.height = `${Math.min(69, thing.offsetHeight)}px`;
    thing.parentElement.insertBefore(placeholder, thing);
    thing.placeholder = placeholder;

    this.element.appendChild(thing);

    setTimeout(() => {
      if (this.isCategory(thing)) {
        this.closeDrawerAfterDropping(thing);
      }
    }, 1);
  }

  stopDragging() {
    if (!this.currentThing) {
      return
    }

    const thing = this.currentThing;

    if (thing.placeholder) {
      thing.placeholder.parentElement.insertBefore(thing, thing.placeholder);
      thing.placeholder.parentElement.removeChild(thing.placeholder);
      thing.placeholder = null;
    }
    thing.style.top = null;
    thing.style.left = null;
    thing.style.width = null;
    thing.style.height = null;
    thing.classList.remove("sortie-moving");
    thing.mouseCurrentPos = null;
    thing.mouseStartPos = null;
    thing.hasStartedMoving = false;

    if (this.isDragging) {
      if (this.isCategory(thing)) {
        setTimeout(() => {
          this.openDrawerAfterPickingUp(thing);
        }, 1);
      }
    }

    this.currentThing = null;

    this.scrollingUp = false;
    this.scrollingDown = false;

    if (this.currentCategory) {
      this.currentCategory.openedByDragging = false;
      this.currentCategory = null;
    }

    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);

    if (this.isDragging) {
      this.reOrderPositions();
      this.categoryTargets.forEach(cat => {
        if (this.isCategoryEmpty(cat)) {
          cat.dataset.drawerShouldClose = "true";
        }
      });
      document.body.classList.remove('sortie-is-dragging');
      this.isDragging = false;
    }
  }

  captureOriginalPosition(thing, pos) {
    thing.originalPos = getPosition(thing);
    thing.mouseStartPos = pos
    thing.originalMouseOffset = {
      x: pos.x - thing.originalPos.x,
      y: pos.y - thing.originalPos.y
    };
    thing.mouseCurrentPos = thing.mouseStartPos;
  }

  @bound
  onMouseMove(originalEvent) {
    let event = originalEvent;
    if (originalEvent.touches || originalEvent.changedTouches) {
      event = originalEvent.touches[0] || originalEvent.changedTouches[0]
    }

    const newMousePos = { x: event.pageX, y: event.pageY }
    const thing = this.currentThing;

    if (!thing) {
      return;
    }

    if (Math.abs(thing.mouseStartPos.y - newMousePos.y) > 5) {
      if (!thing.hasStartedMoving) {
        this.startDragging(thing);
      }

      if (event.clientY <= this.topEdge) {
        this.scrollingUp = true;
        this.scrollingDown = false;
        this.keepScrollingUp();
      } else if (event.clientY >= this.bottomEdge) {
        this.scrollingUp = false;
        this.scrollingDown = true;
        this.keepScrollingDown();
      } else {
        this.scrollingUp = false;
        this.scrollingDown = false;
      }

      thing.style.top = `${newMousePos.y - thing.originalMouseOffset.y + document.body.getBoundingClientRect().top}px`;
      thing.style.left = `${newMousePos.x - thing.originalMouseOffset.x + document.body.getBoundingClientRect().left}px`;

      thing.mouseCurrentPos = newMousePos;

      const [el, elType] = this.getMeaningfulElementAndTypeUnderMouse(event.pageX, event.pageY);

      if (!this.isCategory(thing)) {
        const currentCategory = this.categoryTargets.find(cat => cat.contains(el));
        if (currentCategory !== this.currentCategory) {
          setTimeout(() => {
            this.onLeaveCategory(this.currentCategory);
            this.onEnterCategory(currentCategory);
            this.currentCategory = currentCategory;
          }, 0);
        }
      }

      switch (elType) {
        case 'thing':
          {
            if (this.isTopLevelThing(el)) {
              const [before, after] = this.getElementsBeforeAndAfter(event.pageY);
              this.highlightBeforeAfter(before, after, this.containerTarget);
            } else {
              let subSortie = this.subSortie(el);
              const [before, after] = this.getElementsBeforeAndAfterInSubSortie(subSortie, event.pageY);
              this.highlightBeforeAfter(before, after, subSortie);
            }
          }
          break;
        case 'category':
          {
            const subSortie = this.getCategorySubsortie(el);
            const [before, after] = this.getElementsBeforeAndAfterInSubSortie(subSortie, event.pageY);
            this.highlightBeforeAfter(before, after, subSortie);
          }
          break;
        case 'sortie':
          {
            const [before, after] = this.getElementsBeforeAndAfter(event.pageY);
            this.highlightBeforeAfter(before, after, this.containerTarget);
          }
          break;
        default:
          break;
      }
    }
  }

  @bound
  onMouseUp(event) {
    const thing = this.currentThing;

    if (!thing) {
      return;
    }

    this.stopDragging();
    if (event.cancelable) event.preventDefault();
    event.stopPropagation();
  }

  onTouchHold(originalEvent, thing) {
    let event = originalEvent;
    if (originalEvent.touches || originalEvent.changedTouches) {
      event = originalEvent.touches[0] || originalEvent.changedTouches[0]
    }

    return () => {
      if (this.touchMoved) {
        return;
      }

      this.preventScrolling = true;
      this.currentThing = thing;
      this.captureOriginalPosition(thing, { x: event.pageX, y: event.pageY });
      this.startDragging(this.currentThing);
    };
  }

  @bound
  onTouchMove(event) {
    this.touchMoved = true;

    if (this.currentThing) {
      this.onMouseMove(event);
    }
  }

  @bound
  onTouchEnd(event) {
    this.preventScrolling = false;
    this.touchMoved = false;

    const thing = this.currentThing;

    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('touchcancel', this.onTouchEnd);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('contextmenu', this.onContextMenu);

    clearTimeout(this.tapTimeout);

    if (!thing) {
      return;
    }

    if (event.cancelable) event.preventDefault();

    this.stopDragging();
  }

  keepScrollingDown() {
    window.scrollBy(0, SCROLL_SPEED);
    if (this.scrollingDown) {
      requestAnimationFrame(() => {
        this.keepScrollingDown();
      });
    }
  }

  keepScrollingUp() {
    window.scrollBy(0, -SCROLL_SPEED);
    if (this.scrollingUp) {
      requestAnimationFrame(() => {
        this.keepScrollingUp();
      });
    }
  }

  onMove(before, after, sortie) {
    this.currentThing.placeholder.parentElement.removeChild(this.currentThing.placeholder);

    if (after) {
      after.parentElement.insertBefore(this.currentThing.placeholder, after);
    } else {
      sortie.appendChild(this.currentThing.placeholder);
    }

    this.beforeElement = before;
    this.afterElement = after;
    this.sortieElement = sortie;
  }

  highlightBeforeAfter(before, after, sortie) {
    if (before !== this.beforeElement || after !== this.afterElement || sortie !== this.sortieElement) {
      this.onMove(before, after, sortie);
    }
  }

  isTopLevelThing(thing) {
    return !this.subSortie(thing);
  }

  subSortie(thing) {
    return thing.parentElement.closest('[data-target*="sortie.subSortie"]');
  }

  getCategorySubsortie(cat) {
    return cat.querySelector('[data-target*="sortie.subSortie"]');
  }

  isCategoryEmpty(cat) {
    return this.getThingsInCategory(cat).length <= 0;
  }

  getThingsInSubSortie(subSortie) {
    return this.thingTargets.filter(thing => this.subSortie(thing) === subSortie);
  }

  getThingsInCategory(cat) {
    return this.getThingsInSubSortie(this.getCategorySubsortie(cat));
  }

  getTopLevelThings() {
    return this.thingTargets.filter(thing => this.isTopLevelThing(thing));
  }

  getElementsBeforeAndAfterInThings(things, dragY) {
    let elementBefore = null;
    let elementAfter = null;
    for (let thing of things.filter(t => t !== this.currentThing)) {
      const { y } = getCenter(thing);
      if (y < dragY) {
        elementBefore = thing;
      } else {
        elementAfter = thing;
        break;
      }
    }
    return [elementBefore, elementAfter];
  }

  getElementsBeforeAndAfter(dragY) {
    return this.getElementsBeforeAndAfterInThings(this.getTopLevelThings(), dragY);
  }

  getElementsBeforeAndAfterInSubSortie(subSortie, dragY) {
    return this.getElementsBeforeAndAfterInThings(this.getThingsInSubSortie(subSortie), dragY);
  }

  getMeaningfulElementAndTypeUnderMouse(dragX, dragY) {
    const thing = this.getThingUnderMouse(dragX, dragY, this.getTopLevelThings());

    if (!thing) return [this.containerTarget, 'sortie'];

    if (this.isCategory(thing)) {
      if (this.isCategory(this.currentThing)) {
        return [thing, 'thing'];
      }
      const subSortie = this.getCategorySubsortie(thing);
      const subThing = this.getThingUnderMouse(dragX, dragY, this.getThingsInSubSortie(subSortie));
      if (subThing) {
        return [subThing, 'thing'];
      }
      return [thing, 'category'];
    }
    return [thing, 'thing'];
  }

  getThingUnderMouse(dragX, dragY, things) {
    for (let thing of things.filter(t => t !== this.currentThing)) {
      const { x, y, width, height } = getPosition(thing);
      if (dragX >= x && dragX <= (x + width) && dragY >= y && dragY <= (y + height)) {
        return thing;
      }
    }
    return null;
  }

  isCategory(thing) {
    return thing.dataset.target && thing.dataset.target.includes('sortie.category');
  }

  @bound
  onContextMenu(event) {
    if (event.cancelable) event.preventDefault();
    event.stopPropagation();
  }

  onEnterCategory(cat) {
    if (!cat) {
      return;
    }

    this.openDrawerWhenDraggingOver(cat);
  }

  onLeaveCategory(cat) {
    if (!cat) {
      return;
    }

    this.closeDrawerAfterDraggingOver(cat);
  }

  closeDrawerAfterDraggingOver(cat) {
    if (cat.dataset.drawerOpen === "true" && cat.openedByDragging) {
      cat.openedByDragging = false;
      cat.dataset.drawerShouldClose = "true";
    }
  }

  openDrawerWhenDraggingOver(cat) {
    if (cat.dataset.drawerOpen !== "true") {
      cat.openedByDragging = true;
      cat.dataset.drawerShouldOpen = "true";
    }
  }

  closeDrawerAfterDropping(cat) {
    if (cat.dataset.drawerOpen === "true") {
      cat.closedByPickingUp = true;
      cat.dataset.drawerShouldClose = "true";
    }
  }

  openDrawerAfterPickingUp(cat) {
    if (cat.dataset.drawerOpen !== "true" && cat.closedByPickingUp) {
      cat.closedByPickingUp = false;
      cat.dataset.drawerShouldOpen = "true";
    }
  }

  reOrderPositions() {
    const positionInterval = 1000;

    this.thingTargets.forEach((thing, index) => {
      if (this.isCategory(thing)) {
        this.reOrderCategoryTicketTypesPositions(thing, index, positionInterval);
      } else if (this.isTopLevelThing(thing)) {
        thing.querySelectorAll('input')[0].value = positionInterval * (index + 1);
        thing.querySelectorAll('input')[1].value = "";
      }
    });

    this.submitForm();
  }

  submitForm() {
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit');
    }
  }

  reOrderCategoryTicketTypesPositions(thing, index, positionInterval) {
    let categoryTicketTypePositionInterval = 1;
    const subSortie = this.getCategorySubsortie(thing);

    thing.querySelectorAll('input')[0].value = positionInterval * (index + 1);
    let categoryIDInput = thing.querySelectorAll('input')[1];

    this.getThingsInSubSortie(subSortie).forEach((subThing, categoryIndex) => {
      let categoryTicketTypePosition = subThing.querySelectorAll('input');

      categoryTicketTypePosition[0].value = (positionInterval * (index + 1)) + (categoryTicketTypePositionInterval * (categoryIndex + 1))
      categoryTicketTypePosition[1].value = categoryIDInput.value
    });
  }

  checkEmptyDrawer(event) {
    const cat = event.target.closest('[data-target*="sortie.category"]')
    if (cat && this.isCategoryEmpty(cat)) {
      this.toggleDrawerIconRotation(cat);

      if (event.cancelable) event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    } else {
      Rails.fire(event.target, 'sortie:open-drawer');
    }
  }

  toggleDrawerIconRotation(drawer) {
    const drawerToggleIcon = drawer.querySelector('.drawer__toggle-icon');

    drawerToggleIcon.classList.add('drawer__toggle-icon--open');
    setTimeout(() => {
      drawerToggleIcon.classList.remove('drawer__toggle-icon--open');
    }, 180);
  }
}
