import ApplicationController from "javascript/controllers/application_controller";
import * as d3 from 'd3-selection';
import * as britecharts from 'britecharts';


function getContainerWidth(container) {
  return container.node() ? container.node().getBoundingClientRect().width : 0;
}

function getGraphHeight(container) {
  const containerWidth = getContainerWidth(container);

  if (containerWidth >= 640) {
    return 330;
  } else {
    return 260;
  }
}

export default class extends ApplicationController {
  static targets = [
    'statsPath',
    'graphContainer',
    'graphElement',
    'openPercentageRate',
    'clickPercentageRate',
    'unqiueOpens',
    'unqiueClicks',
    'opensReportCard',
    'clicksReportCard'
  ]

  get statsPath() {
    return this.statsPathTarget.getAttribute('data-stats-path');
  }

  connect(){
    this.redrawChart = this.redrawChart.bind(this);

    this.sendStatsGetRequest(this.statsPath)
      .then((data) => {
        this.buildChart(data);
        this.setReportCardData(data.report_clicks, data.report_opens);
      });

    this.graphContainerWidth = this.graphElementTarget.offsetWidth;
  }

  disconnect() {
    this.window.removeEventListener('resize', this.redrawChart);
  }

  redrawChart() {
    const newContainerWidth = getContainerWidth(this.container);

    this.lineChart.height(getGraphHeight(this.container));
    this.lineChart.width(newContainerWidth);
    this.container.call(this.lineChart);
  }

  sendStatsGetRequest(url) {
    this.data.set('loading', 'true');

    return window.fetch(url).then((response) => {
      return response.json();
    }).then((data) => {
      return data;
    });
  }

  buildChart(data) {
    this.container = d3.select('.js-line-chart-container');
    this.lineChart = britecharts.line();
    const chartTooltip = britecharts.tooltip();
    let tooltipContainer;
    let dataset;

    const lineMargin = {top:60, bottom: 50, left: 50, right: 30};

    dataset = data.graph_data;

    this.lineChart
      .isAnimated(true)
      .grid('vertical')
      .tooltipThreshold(0)
      .height(getGraphHeight(this.container))
      .width(this.graphContainerWidth)
      .margin(lineMargin)
      .lineCurve('basis')
      .dateLabel('fullDate')
      .on('customMouseOver', chartTooltip.show)
      .on('customMouseMove', chartTooltip.update)
      .on('customMouseOut', chartTooltip.hide)

    this.container.datum(dataset).call(this.lineChart);

    chartTooltip.title('Campaign Performance');
    chartTooltip.topicsOrder(dataset.dataByTopic.map(function(topic) {
      return topic.topic;
    }));

    tooltipContainer = d3.select('.js-line-chart-container .metadata-group .hover-marker');
    tooltipContainer.datum([]).call(chartTooltip);

    this.window.addEventListener('resize', this.redrawChart);
  }

  setReportCardData(clickStatsData, openStatsData) {
    this.openPercentageRateTarget.innerHTML = openStatsData.open_rate;
    this.unqiueOpensTarget.innerHTML = openStatsData.unique_opens;

    this.clickPercentageRateTarget.innerHTML = clickStatsData.click_rate;
    this.unqiueClicksTarget.innerHTML = clickStatsData.unique_clicks;

    this.setSimpleReportCardContentColor(openStatsData.open_rate, "opens");
    this.setSimpleReportCardContentColor(openStatsData.open_rate, "clicks", 6, 3);

    this.data.set('loading', 'false');
  }

  setSimpleReportCardContentColor(statRate, reportCardType, successPercentage = 25, warningPercentage = 15) {
    let target = null;

    if (reportCardType === "opens") {
      target = this.opensReportCardTarget;
    } else if (reportCardType === "clicks") {
      target = this.clicksReportCardTarget;
    }

    if (target != null) {
      if (statRate >= successPercentage) {
        target.classList.add("campaign-report-card-simple__content--success");
      } else if(statRate < warningPercentage) {
        target.classList.add("campaign-report-card-simple__content--warning");
      }
    }
  }
}
