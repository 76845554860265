import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { pxToInt, unsetTransition, setTransition, raf, getElementsWindow, wrapInDiv, getTotalHeight, getTransition } from 'javascript/utils/utils'

export default class extends ApplicationController {
  static targets = ["content", "scrollStart"];

  get isToggling() {
    return false || (this.data.get('toggling') === 'true');
  }

  set isToggling(value) {
    return this.data.set('toggling', value);
  }

  get isOpen() {
    return false || (this.data.get('open') === 'true');
  }

  set isOpen(value) {
    return this.data.set('open', value);
  }

  get shouldOpen() {
    return false || (this.data.get('shouldOpen') === 'true');
  }

  get shouldClose() {
    return false || (this.data.get('shouldClose') === 'true');
  }

  set shouldOpen(value) {
    return this.data.set('shouldOpen', value);
  }

  set shouldClose(value) {
    return this.data.set('shouldClose', value);
  }

  get enabled() {
    return false || (this.data.get('enabled') === 'true');
  }

  get isInSortableFallback() {
    return !!this.sortableFallbackParent;
  }

  get sortableFallbackParent() {
    return this.element.closest('.sortable-fallback')
  }

  set enabled(value) {
    if (!value) {
      this.disable();
    }
    return this.data.set('enabled', value);
  }

  get shouldScrollIntoView() {
    return ( false || (this.data.get('shouldScroll') == 'true')) && this.hasScrollStartTarget;
  }

  connect() {
    this.originalStyleAttribute = this.contentTarget.getAttribute('style');

    this.contentWrapper = wrapInDiv(this.contentTarget, 'data-drawer-wrapped');
    this.transition = getTransition(this.contentTarget);
    this.contentTarget.style.transition = '';

    this.setupAttributeObserver();
    this.enforceInitialState();
    this.drawerContentErrorObserver();

    if (this.isInSortableFallback && this.isOpen) {
      this.forceClose();
      setTimeout(() => {
        this.sortableFallbackParent.style.height = `${this.element.clientHeight}px`;
      }, 10);
    }
  }

  disconnect() {
    this.attributeObserver.disconnect();
    this.contentTarget.setAttribute('style', this.originalStyleAttribute);
    this.originalStyleAttribute = null;
  }

  toggle(event) {
    if (!this.enabled) return;

    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }

    event.preventDefault();
    event.stopPropagation();
  }

  close() {
    const contentHeight = getTotalHeight(this.contentTarget);
    this.beforeTransition();

    this.contentWrapper.style.overflow = 'hidden';
    this.contentWrapper.style.height = `${contentHeight}px`;

    if (contentHeight === 0) {
      this.onTransitionEnd();
    } else {
      raf(() => {
        setTransition(this.contentWrapper, this.transition);
        raf(() => {
          this.contentWrapper.addEventListener('transitionend', this.onTransitionEndHandler, false);
          this.contentWrapper.style.height = 0;
        });
      });
    }
  }

  open() {
    const contentTarget = this.contentTarget;
    const contentHeight = getTotalHeight(this.contentTarget);
    const scrollTarget = this.scrollStartTarget;
    this.beforeTransition();

    setTransition(this.contentWrapper, this.transition);
    this.contentWrapper.style.overflow = 'hidden';
    this.contentWrapper.style.height = 0;
    this.contentWrapper.addEventListener('transitionend', this.onTransitionEndHandler, false);
    this.contentWrapper.style.height = `${contentHeight}px`;

    if (contentHeight === 0) {
      this.onTransitionEnd();
    }

    setTimeout(function () {
      if (this.shouldScrollIntoView && scrollTarget.scrollIntoView) {
        scrollTarget.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 180);
  }

  disable() {
    this.contentWrapper.removeEventListener('transitionend', this.onTransitionEndHandler);

    if (this.isToggling) {
      this.onTransitionEnd();
    }

    if (!this.isOpen) {
      this.open();
      this.onTransitionEnd();
    }
  }

  @bound
  onTransitionEndHandler({ target }) {
    if (target === this.contentWrapper) this.onTransitionEnd();
  }

  @bound
  onTransitionEnd() {
    if (!this.isToggling) return;
    this.isToggling = false;

    this.contentWrapper.removeEventListener('transitionend', this.onTransitionEndHandler);

    unsetTransition(this.contentWrapper);
    this.afterTransition();
  }

  beforeTransition() {
    this.isToggling = true;
  }

  afterTransition() {
    if (this.isOpen) {
      this.forceClose();
      this.isOpen = false;
    } else {
      this.forceOpen();
      this.isOpen = true;
    }
    this.isToggling = false;
    this.shouldOpen = false;
    this.shouldClose = false;
  }

  forceClose() {
    this.contentWrapper.style.overflow = 'hidden';
    this.contentWrapper.style.height = 0;
  }

  forceOpen() {
    this.contentWrapper.style.overflow = 'auto';
    this.contentWrapper.style.height = 'auto';
  }

  setupAttributeObserver() {
    this.attributeObserver = new MutationObserver(mutationList => {
      this.setupShouldCloseMutationObserver(mutationList);
      this.setupShouldOpenMutationObserver(mutationList);
      this.setupEnabledMutationObserver(mutationList);
    });
    this.attributeObserver.observe(this.element, { attributes: true });
  }

  setupShouldCloseMutationObserver(mutationList) {
    if (mutationList.find(x => x.attributeName === `data-${this.scope.identifier}-should-open`)) {
      if (this.shouldOpen && !this.isOpen && !this.isToggling) {
        this.open();
      }
    }
  }

  setupShouldOpenMutationObserver(mutationList) {
    if (mutationList.find(x => x.attributeName === `data-${this.scope.identifier}-should-close`)) {
      if (this.shouldClose && this.isOpen && !this.isToggling) {
        this.close();
      }
    }
  }

  setupEnabledMutationObserver(mutationList) {
    if (mutationList.find(x => x.attributeName === `data-${this.scope.identifier}-enabled`)) {
      if (!this.enabled) {
        this.disable();
      }
    }
  }

  enforceInitialState() {
    if (!this.data.has('open')) {
      this.data.set('open', true);
    }
    if (!this.data.has('enabled')) {
      this.data.set('enabled', true);
    }
    if (this.isOpen) {
      this.forceOpen();
    } else {
      this.forceClose();
    }
  }

  rotateToggleIcon() {
    if (this.element.getElementsByClassName('drawer__toggle-icon')[0] === undefined) {
      return;
    }

    if (this.contentTarget.childNodes.length === 0) {
      return;
    }

    let e = this.element.getElementsByClassName('drawer__toggle-icon')[0];

    if (this.isOpen) {
      e.classList.remove('drawer__toggle-icon--open');
    } else {
      e.classList.add('drawer__toggle-icon--open');
    }
  }

  drawerContentErrorObserver() {
    if (this.element.getElementsByClassName('alert__message')[0] === undefined) {
      return;
    } else {
      this.open();
    }
  }
}