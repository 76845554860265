import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {

  updateDurationDisplayAttributes() {
    this.durationDisplayElement.setAttribute('data-duration-display-start', this.startDateTimeElement.value);
    this.durationDisplayElement.setAttribute('data-duration-display-end', this.endDateTimeElement.value);
  }

  @bound
  onDateTimeElementChange() {
    this.updateDurationDisplayAttributes();
  }

  connect() {
    this.durationDisplayElement = window.document.querySelector('[data-controller="duration-display"]');
    this.startDateTimeElement = window.document.querySelector('[data-name="date-time-input-start"]');
    this.endDateTimeElement = window.document.querySelector('[data-name="date-time-input-end"]');

    this.startDateTimeElement.addEventListener('change', this.onDateTimeElementChange);
    this.endDateTimeElement.addEventListener('change', this.onDateTimeElementChange);
    this.updateDurationDisplayAttributes();
  }

  disconnect() {
    this.startDateTimeElement.removeEventListener('change', this.onDateTimeElementChange);
    this.endDateTimeElement.removeEventListener('change', this.onDateTimeElementChange);
  }

}
