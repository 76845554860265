import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  static targets = [
    'item',
    'scrollList',
    'textSearchInput',
    'textSearchClearButton',
    'noResultsMessage',
    'noResultsMessageContent',
  ]


  updateTextSearchClearButtonVisibility(value) {
    this.textSearchClearButtonTarget.style.display = (value.length === 0) ? 'none' : 'block';
  }


  updateNoResultsMessageVisibility(matchesCount) {
    if (this.hasNoResultsMessageTarget) {
      this.noResultsMessageTarget.style.display = (matchesCount === 0) ? 'block' : 'none';
    }
  }


  updateNoResultsMessageContent(searchString) {
    this.noResultsMessageContentTarget.innerHTML = `'${searchString}'`;
  }


  onTextSearchInputChange(e) {
    const value = e.target.value;
    let matchesCount = 0;

    Array.prototype.forEach.call(this.itemTargets, (item) => {
      const itemValue = item.textContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
      const isItemMatched = itemValue.toLowerCase().indexOf(value.toLowerCase()) > -1;

      if (isItemMatched) {
        matchesCount += 1;
      }

      item.style.display = isItemMatched ? 'block' : 'none';
    });


    this.scrollListTarget.scrollTop = 0;

    this.updateTextSearchClearButtonVisibility(value);
    this.updateNoResultsMessageVisibility(matchesCount);
    this.updateNoResultsMessageContent(value);
  }


  onTextSearchClearButtonClick() {
    this.textSearchInputTarget.value = '';
    this.onTextSearchInputChange({
      target: {
        value: ''
      }
    });
  }


  connect() {
    this.updateTextSearchClearButtonVisibility(this.textSearchInputTarget.value);
    this.updateNoResultsMessageVisibility(1);
  }

}
