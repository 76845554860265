import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import replaceContent from "javascript/utils/replace_content";
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = [
    'form',
    'searchInput',
    'itemList',
  ];

  connect() {
    this.openDataAttributeName = "data-should-show";
  }

  @bound
  onDocumentClick(e) {
    this.toggle(e);
  }

  close() {
    this.element.setAttribute(this.openDataAttributeName, "");
    window.document.removeEventListener('click', this.onDocumentClick);
  }

  open() {
    this.element.setAttribute(this.openDataAttributeName, "true");
    this.searchInputTarget.focus();
    window.setTimeout(() => {
      window.document.addEventListener('click', this.onDocumentClick);
    }, 0);
  }

  toggle(e) {
    if (this.element.getAttribute(this.openDataAttributeName) === "true") {
      if (e.target.closest('.organiser-selector__selected')) {
        this.close();
      }
      if (!e.target.closest('.organiser-selector')) {
        this.close();
      }
    } else {
      this.open();
    }
  }

  onSearchInputClearIconClick() {
    this.searchInputTarget.value = '';
    this.onSearchInputUpdated();
  }

  getSearchInputValue(e) {
    if (e) {
      return e.target.value;
    }
    return this.searchInputTarget.value;
  }

  onSearchInputUpdated(e) {
    const value = this.getSearchInputValue(e);

    if (this.hasFormTarget && value) {
      Rails.fire(this.formTarget, 'submit');
    }

    if (value) {
      this.searchInputTarget.classList.remove('has-content');
    } else {
      this.updateList('<div class="util-inset">You have more than 10 organisers. Use the search input above to find an organiser</div>');
      this.searchInputTarget.classList.add('has-content');
    }
  }

  onFormSubmitBegin() {
    this.updateList('<div class="util-inset">&nbsp;</div>');
  }

  onFormSubmitError() {
    this.updateList('<div class="util-inset">Something went wrong</div>');
  }

  onFormSubmitSuccess({ detail: [response] }) {
    if (response.body.childNodes.length > 0) {
      replaceContent(this.itemListTarget, response.body.childNodes);
    } else {
      this.updateList('<div class="util-inset">No results were found</div>');
    }
  }

  updateList(content) {
    this.itemListTarget.innerHTML = content;
  }
}
