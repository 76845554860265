import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'list',
    'prevButton',
    'nextButton',
    'itemTitle',
  ];

  getSelectedIndex() {
    return this.selectedIndex;
  }

  updateView() {
    let itemsToShow = parseInt(this.data.get('itemsToShow'), 10) || 1;

    if (window.innerWidth >= 1240) {
      itemsToShow = parseInt(this.data.get('itemsToShowOnLarge'), 10) || 1;
    }

    const listFirstChild = this.listTarget.childNodes[0];
    const listLastChild = this.listTarget.childNodes[this.listTarget.childNodes.length - 1];

    const listFirstChildWidth = listFirstChild.offsetWidth;
    const listLastChildLeft = listLastChild.offsetLeft;
    const listLastChildWidth = listLastChild.offsetWidth;
    const listFirstChildMarginRight = parseInt(window.getComputedStyle(listFirstChild).getPropertyValue('margin-right'), 10);
    const listLastChildMarginRight = parseInt(window.getComputedStyle(listLastChild).getPropertyValue('margin-right'), 10);

    const totalWidthOfItems = listLastChildLeft + listLastChildWidth + listLastChildMarginRight;

    const leftOffsetOnSmall = parseInt(this.data.get('leftOffsetOnSmall'), 10) || 0;
    const leftOffsetOnLarge = parseInt(this.data.get('leftOffsetOnLarge'), 10) || 0;
    let leftOffset = 0;

    if (window.innerWidth < 1240) {
      leftOffset += leftOffsetOnSmall;
    } else if (window.innerWidth >= 1240) {
      leftOffset += leftOffsetOnLarge;
    }

    const xMinPosition = -(totalWidthOfItems - this.listTarget.offsetWidth);
    let xNewPosition = -(this.selectedIndex * (listFirstChildWidth + listFirstChildMarginRight));

    if (this.selectedIndex > 0) {
      xNewPosition += leftOffset;
    }

    if (xNewPosition <= xMinPosition || this.selectedIndex >= (this.totalListItems - itemsToShow)) {
      xNewPosition = xMinPosition;
      this.onReachedEnd();
    } else {
      if (this.selectedIndex === 0) {
        this.onReachedStart();
      } else if (this.selectedIndex === this.totalListItems - 1) {
        this.onReachedEnd();
      } else {
        this.toggleButtonDisplay('prev', true);
        this.toggleButtonDisplay('next', true);
      }
    }

    if (window.innerWidth >= 1240) {
      Array.prototype.forEach.call(this.listTarget.childNodes, (listItemDOMNode, index) => {
        if (index < this.selectedIndex) {
          listItemDOMNode.style.opacity = 0;
        } else {
          listItemDOMNode.style.opacity = 1;
        }
      });
    } else {
      Array.prototype.forEach.call(this.listTarget.childNodes, (listItemDOMNode) => {
        listItemDOMNode.style.opacity = 1;
      });
    }

    if (this.data.get('fadeOutItemsBeforeAndAfterSelected') === 'true') {
      Array.prototype.forEach.call(this.listTarget.childNodes, (listItemDOMNode, index) => {
        if (index === this.selectedIndex) {
          listItemDOMNode.style.opacity = 1;
        } else {
          listItemDOMNode.style.opacity = 0;
        }
      });
    }

    Array.prototype.forEach.call(this.listTarget.childNodes, (listItemDOMNode, index) => {
      const itemIframe = listItemDOMNode.querySelector('iframe');
      if (itemIframe) {
        if (index === this.selectedIndex) {
          itemIframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        } else {
          itemIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
      }
    });

    if (this.listTarget.offsetWidth > totalWidthOfItems) {
      xNewPosition = 0;
      this.toggleButtonDisplay('prev', false);
      this.toggleButtonDisplay('next', false);
    }

    this.listTarget.style.transform = 'translateX(' + xNewPosition + 'px)';
  }

  toggleButtonDisplay(buttonName, shouldShow = false) {
    this[buttonName + 'ButtonTarget'].style.display = shouldShow === true ? 'block' : 'none';
  }

  onReachedStart() {
    this.toggleButtonDisplay('prev');
    this.toggleButtonDisplay('next', true);
  }

  onReachedEnd() {
    this.toggleButtonDisplay('next');
    this.toggleButtonDisplay('prev', true);
  }

  updateSelectedIndex(index) {
    if (index < 0) {
      index = 0;
    } else if (index > this.totalListItems - 1) {
      index = this.totalListItems - 1;
    }

    this.selectedIndex = index;
    const selectedItem = this.listTarget.childNodes[this.selectedIndex];

    if (this.hasItemTitleTarget) {
      this.itemTitleTarget.innerHTML = selectedItem.getAttribute('data-title') || '';
    }

    this.updateView();
  }

  onPrevButtonClick() {
    this.updateSelectedIndex(this.getSelectedIndex() - 1);
  }

  onNextButtonClick() {
    this.updateSelectedIndex(this.getSelectedIndex() + 1);
  }

  @bound
  onWindowResize() {
    this.updateView();
  }

  connect() {
    this.totalListItems = this.listTarget.childNodes.length;
    this.updateSelectedIndex(parseInt(this.data.get('selectedIndex'), 10) || 0);
    window.addEventListener('resize', this.onWindowResize);
  }

  disconnect() {
    window.removeEventListener('resize', this.onWindowResize);
  }

}
