import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['quantityAvailableLabel', 'select', 'inputAmount', 'warnings', 'quantityWarning', 'sharingRequiredWarning'];

  connect() {
    if (this.selectTarget.options.length > 0) {
      this.updateQuantityLabel(null);
    }
  }

  updateQuantityLabel() {
    const quantityAvailable = this.selectTarget.options[this.selectTarget.selectedIndex].getAttribute('data-quantity-available');
    this.quantityAvailableLabelTarget.innerHTML = quantityAvailable ? quantityAvailable : 'unlimited';
    this.checkAmount();
  }

  sharingRequired() {
    if (this.selectTarget.getAttribute('data-sharing-required') === "true") {
      return true;
    }

    return false;
  }

  onInput(e) {
    this.removeInvalidCharacters(e);
    this.checkAmount(e);
  }

  removeInvalidCharacters(e) {
    const invalidCharacters = [
      '-',
      '.',
      ',',
      ' ',
      'e',
    ];
    const currentValue = e.currentTarget.value;
    const cleanedValue = currentValue.split('').map((character) => {
      if (invalidCharacters.indexOf(character) !== -1) {
        return '';
      }
      return character;
    }).join('');

    this.inputAmountTarget.value = cleanedValue;
  }

  checkAmount(e) {
    if (this.quantityAvailableLabelTarget.innerHTML !== 'unlimited' && ( parseInt(this.quantityAvailableLabelTarget.innerHTML) < parseInt(this.inputAmountTarget.value))) {
      this.showWarning('quantity');
    } else {
      this.hideWarning('quantity');
    }

    if (this.sharingRequired() && ( parseInt(this.inputAmountTarget.value) > 1) ) {
      this.showWarning('sharingRequired');
    } else {
      this.hideWarning('sharingRequired');
    }
  }

  showWarning(warningType) {
    switch (warningType) {
      case 'quantity':
        this.quantityWarningTarget.classList.add('modal__warning-message--visible');
        break;
      case 'sharingRequired':
        this.sharingRequiredWarningTarget.classList.add('modal__warning-message--visible');
        break;
    }
  }

  hideWarning(warningType) {
    switch (warningType) {
      case 'quantity':
        this.quantityWarningTarget.classList.remove('modal__warning-message--visible');
        break;
      case 'sharingRequired':
        this.sharingRequiredWarningTarget.classList.remove('modal__warning-message--visible');
        break;
    }
  }
}