import ApplicationController from "javascript/controllers/application_controller";
import bound from 'bound-decorator';
export default class extends ApplicationController {

  static targets = ['colorPicker', 'characterCount'];

  addQuillDependency(callback) {
    const head = window.document.getElementsByTagName('head')[0];

    const scriptElement = window.document.createElement('script');
    scriptElement.src = '//cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.min.js';
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.onload = callback;

    const linkElement = window.document.createElement('link');
    linkElement.href = "//cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.snow.min.css";
    linkElement.type = "text/css";
    linkElement.rel = "stylesheet";

    head.appendChild(scriptElement);
    head.appendChild(linkElement);
  }

  updateFieldWithEditorContent() {
    this.fieldDOMNode.innerHTML = this.editorContentDOMNode.innerHTML;
  }

  updateCharacterCount() {
    const text = this.editorInstance.getText();
    this.characterCountTarget.innerText = `${text.length} / ${this.maxCharacters}`;
  }

  @bound
  setup() {
    this.fieldDOMNode = this.element.querySelector('.rich-textarea__field');
    this.editorContainerDOMNode = this.element.querySelector('.rich-textarea__editor');
    const existingToolbar = this.element.querySelector('.ql-toolbar');

    if (existingToolbar) {
      existingToolbar.remove()
    }

    this.editorInstance = new window.Quill(this.editorContainerDOMNode, {
      modules: {
        toolbar: this.toolbarOptions
      },
      placeholder: this.element.getAttribute('placeholder') || '',
      bounds: this.element,
      theme: 'snow'
    });

    const toolbar = this.editorInstance.getModule('toolbar');
    toolbar.addHandler('color', this.showColorPicker);

    this.editorContentDOMNode = this.element.querySelector('.ql-editor');
    this.updateFieldWithEditorContent();
    this.updateCharacterCount();

    this.editorInstance.on('text-change', () => {
      this.updateFieldWithEditorContent();
      this.updateCharacterCount();
    });

    this.setEditorConfiguration();
  }

  setEditorConfiguration() {
    const Delta = window.Quill.import('delta');

    this.editorContentDOMNode.addEventListener('paste', (e) => {
      const clipboardContent = e.clipboardData.getData('text/plain');
      if (this.maxInputReached(clipboardContent.length - 1)) {
        e.preventDefault();
      }
    });

    this.editorContentDOMNode.addEventListener('keydown', (e) => {
      if (this.maxInputReached() && !(e.code === 'Backspace' || e.code === 'Delete')) {
        e.preventDefault();
      }
    });

    this.editorInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      return delta.compose(new Delta().retain(delta.length(), { background: 'none' }));
    });
  }

  maxInputReached(offset = 0) {
    return (this.editorInstance.getLength() + offset) >= this.maxCharacters;
  }

  colorPickerChanged() {
    const editor = this.findEditor();
    editor.format('color', this.colorPickerTarget.value);
  }

  findEditor() {
    if (window.Quill) {
      const editorDOMNode = this.element.querySelector('.rich-textarea__editor');
      return window.Quill.find(editorDOMNode);
    }
  }

  @bound
  showColorPicker(value) {
    if (value === 'color-picker') {
      let picker = this.element.querySelector('.rich-textarea__color-picker');
      if (!picker) {
        const container = this.element.querySelector('.rich-textarea');
        picker = document.createElement('input');
        picker.type = 'color';
        picker.classList.add('rich-textarea__color-picker');
        picker.dataset.target = 'rich-textarea.colorPicker';
        picker.dataset.action = 'input->rich-textarea#colorPickerChanged';
        picker.value = '#FF0000';
        container.appendChild(picker);
        setTimeout(() => picker.click(), 0);
      } else {
        picker.click();
      }
    }
  }

  get toolbarOptions() {
    return [
      [{ header: [3, 4, false] }],
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': 'color-picker' }]
    ];
  }

  connect() {
    this.maxCharacters = this.data.get('maxCharacters') || 1000;
    if (window.Quill) {
      if (!this.findEditor()) {
        this.setup();
      }
    } else {
      this.addQuillDependency(() => {
        this.setup();
      });
    }
  }
}
