import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'dataSource',
    'availableFiltersData',
    'listNameInput',
    'matchInput',
    'selectedFilters',
    'filterTemplate',
    'filterInputsTemplate',
    'filterInputs',
    'availableFilterSelector',
  ];

  getAvailableFiltersData() {
    return this.getContentAsJSONOrEmptyArray(this.availableFiltersDataTarget);
  }

  getDataSource() {
    try {
      return JSON.parse(this.dataSourceTarget.innerHTML);
    } catch (e) {
      return {
        name: '',
        match_type: '',
        filters: [],
      };
    }
  }

  getContentAsJSONOrEmptyArray(domTarget) {
    try {
      return JSON.parse(domTarget.innerHTML);
    } catch (e) {
      return [];
    }
  }

  updateDataSourceOutput() {
    this.dataSourceTarget.innerHTML = JSON.stringify(this.dataSource);
  }

  onListNameChange(e) {
    this.dataSource.name = e.currentTarget.value;
    this.updateDataSourceOutput();
  }

  onMatchTypeChange(e) {
    this.dataSource.match_type = e.target.value;
    this.updateDataSourceOutput();
  }

  onRemoveFilterButtonClick(e) {
    this.dataSource.filters.splice(e.currentTarget.dataset.index, 1);
    this.updateDataSourceOutput();
    this.updateView();
  }

  onAddFilterButtonClick() {
    this.createSelectedFilterFromAvailableFilter(this.availableFiltersData[0]);
    this.updateDataSourceOutput();
    this.updateView();
  }

  buildSelectedFilterFromAvailableFilter(availableFilter) {
    return {
      id: availableFilter.id,
      data: {},
    };
  }

  createSelectedFilterFromAvailableFilter(availableFilter) {
    this.dataSource.filters.push(this.buildSelectedFilterFromAvailableFilter(availableFilter));
  }

  cleanSelectedFiltersTarget() {
    while (this.selectedFiltersTarget.firstChild) {
      this.selectedFiltersTarget.removeChild(this.selectedFiltersTarget.firstChild);
    }
  }

  updateView() {
    this.cleanSelectedFiltersTarget();

    this.listNameInputTarget.value = this.dataSource.name;

    this.matchInputTargets.forEach((item) => {
      if (item.value === this.dataSource.match_type) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    this.dataSource.filters.forEach((selectedFilter, selectedFilterIndex) => {
      const template = this.filterTemplateTarget.content.cloneNode(true);
      const filterSelector = template.querySelector('select');

      filterSelector.dataset.index = selectedFilterIndex;
      template.querySelector(`[data-action="click->${this.identifier}#onRemoveFilterButtonClick"]`).dataset.index = selectedFilterIndex;

      filterSelector.innerHTML = this.availableFiltersData.map((item) => {
        return `<option value="${item.id}" ${item.id === selectedFilter.id ? 'selected="selected"' : ''}>${item.label}</option>`;
      }).join('');
      this.selectedFiltersTarget.appendChild(template);

      const filterInputsTemplate = this.filterInputsTemplateTargets.find((item) => {
        return item.getAttribute('name') === filterSelector.value;
      });

      this.filterInputsTargets[selectedFilterIndex].dataset.index = selectedFilterIndex;
      this.filterInputsTargets[selectedFilterIndex].appendChild(filterInputsTemplate.content.cloneNode(true));

      Array.prototype.forEach.call(this.filterInputsTargets[selectedFilterIndex].querySelectorAll('[name]'), (item) => {
        const name = item.getAttribute('name');
        const value = selectedFilter.data[name];

        switch (item.type) {
          case 'checkbox':
            item.checked = value ? true : false;
            break;
          case 'radio':
            item.checked = value === item.value ? true : false;
            break;
          default:
            item.value = typeof (value) !== 'undefined' ? value : '';
        }
      });
    });
  }

  onAvailableFilterSelectorChange(e) {
    const index = e.currentTarget.dataset.index;
    this.dataSource.filters[index] = this.buildSelectedFilterFromAvailableFilter(
      this.availableFiltersData.find((item) => {
        return item.id === e.currentTarget.value;
      })
    );
    this.updateDataSourceOutput();
    this.updateView();
  }

  onFilterInputChange(e) {
    const index = e.currentTarget.closest(`[data-target="${this.identifier}.filterInputs"]`).dataset.index;
    let value = '';

    switch (e.currentTarget.type) {
      case 'checkbox':
        value = e.currentTarget.checked;
        break;
      default:
        value = e.currentTarget.value;
    }

    this.dataSource.filters[index].data[e.currentTarget.name] = value;
    this.updateDataSourceOutput();
  }

  connect() {
    this.availableFiltersData = this.getAvailableFiltersData();
    this.dataSource = this.getDataSource();

    if (!this.dataSource.filters) {
      this.dataSource.filters = [];
    }

    if ((!this.dataSource.filters) || this.dataSource.filters.length === 0) {
      this.createSelectedFilterFromAvailableFilter(this.availableFiltersData[0]);
    }

    if (!this.dataSource.match_type) {
      this.dataSource.match_type = 'ANY';
    }

    this.updateDataSourceOutput();
    this.updateView();
  }

}
