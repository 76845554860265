import ApplicationController from "javascript/controllers/application_controller";
import Tokenfield from 'tokenfield';

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

export default class extends ApplicationController {
  static targets = ['tokenField'];

  get initialItems() {
    if (this.data.has('initialItems')) {
      let itemsString = this.data.get('initialItems');
      if (itemsString) {
        return itemsString.split(' ');
      }
    }
    return [];
  }

  connect() {
    setTimeout(() => {
      this.emailTokenField = new Tokenfield({
        el: this.tokenFieldTarget,
        delimiters: [' ', ',', ';', '\n', '\r'],
        addItemsOnPaste: true,
        itemName: this.data.get('itemName'),
        newItemName: this.data.get('newItemName'),
        setItems: this.initialItems.map(name => ({ name, id: name })),
        addItemOnBlur: true,
      });

      this.emailTokenField.on('input', () => {
        let tokenFieldInput = this.element.querySelector('.tokenfield-input');
        let lastChar = String.fromCharCode(tokenFieldInput.value.charCodeAt(tokenFieldInput.value.length - 1));

        if (isInArray(lastChar, this.emailTokenField._options.delimiters)) {
          tokenFieldInput.value = tokenFieldInput.value.slice(0, -1);
          this.emailTokenField.blur();
          this.emailTokenField.focus();
        }
      });
    }, 100);
  }
}