import ApplicationController from "javascript/controllers/application_controller";

function toMoneyString(value, prefix, suffix, numberOfDecimals) {
  if (typeof numberOfDecimals === 'undefined') {
    numberOfDecimals = 2;
  }
  return prefix + String(value.toFixed(numberOfDecimals)) + suffix;
}

export default class extends ApplicationController {

  static targets = [
    'priceInput',
    'bookingFeePercentageInfoContainer',
    'bookingFeePercentageInfoMessage',
    'bookingFee',
    'customerFeePayable',
    'organiserFeeReceivable',
    'isCustomerChargedForBookingFee',
    'gatewayFee',
    'isCustomerChargedForGatewayFee',
    'feeSection',
  ];

  getBookingFeeMinimum() {
    return parseInt(this.data.get('booking-fee-minimum'), 10);
  }

  getBookingFeeMaximum() {
    return parseInt(this.data.get('booking-fee-maximum'), 10);
  }

  getFee(feeType) {
    const bookingFeeMinimum = this.getBookingFeeMinimum();
    const bookingFeeMaximum = this.getBookingFeeMaximum();
    let feeAsCents = 0;

    if (feeType === 'gateway') {
      feeAsCents = (this.priceAsCents * (this.gatewayFeePercentage / 100));
    } else if (feeType === 'booking') {
      if (this.isFeesOverridden) {
        feeAsCents = this.currentBookingFee;
      } else {
        feeAsCents = (this.priceAsCents * (this.bookingFeePercentage / 100));
        feeAsCents = (!isNaN(bookingFeeMinimum) && (feeAsCents < bookingFeeMinimum)) ? bookingFeeMinimum : feeAsCents;
        feeAsCents = (!isNaN(bookingFeeMaximum) && (feeAsCents > bookingFeeMaximum)) ? bookingFeeMaximum : feeAsCents;
      }
    }

    return feeAsCents;
  }

  resetAndHideFees() {
    this.toggleFeeSection(false);
    this.isCustomerChargedForBookingFeeTarget.checked = false;
    this.isCustomerChargedForBookingFee = false;
    this.isCustomerChargedForGatewayFeeTarget.checked = false;
    this.isCustomerChargedForGatewayFee = false;
  }

  updateOutput() {
    const bookingFeeAsCents = this.getFee('booking');
    let gatewayFeeAsCents = this.getFee('gateway');
    const currencyPrefix = this.data.get('currency-prefix');
    const currencySuffix = this.data.get('currency-suffix');

    if (this.isCustomerChargedForBookingFee) {
      gatewayFeeAsCents = (this.priceAsCents + bookingFeeAsCents) * (this.gatewayFeePercentage / 100);
    }

    let customerFeePayableValueAsCents = this.isCustomerChargedForBookingFee ? this.priceAsCents + bookingFeeAsCents : this.priceAsCents;
    customerFeePayableValueAsCents = this.isCustomerChargedForGatewayFee ? customerFeePayableValueAsCents + gatewayFeeAsCents : customerFeePayableValueAsCents;

    let organiserFeeReceivableValueAsCents = !this.isCustomerChargedForBookingFee ? this.priceAsCents - bookingFeeAsCents : this.priceAsCents;
    organiserFeeReceivableValueAsCents = !this.isCustomerChargedForGatewayFee ? organiserFeeReceivableValueAsCents - gatewayFeeAsCents : organiserFeeReceivableValueAsCents;

    if (this.getBookingFeeMinimum() > 0 && this.getBookingFeeMinimum() === this.getBookingFeeMaximum()) {
      this.bookingFeePercentageInfoMessageTarget.innerHTML = toMoneyString(this.getBookingFeeMinimum() / 100, currencyPrefix, '', 0);
    } else {
      this.bookingFeePercentageInfoMessageTarget.innerHTML = this.bookingFeePercentage + '%';
    }

    if (this.isOnlineStreamingEvent) {
      if (this.priceInputTarget.value === "0" || this.priceInputTarget.value === "") {
        this.resetAndHideFees();

        customerFeePayableValueAsCents = 0;
        organiserFeeReceivableValueAsCents = 0;
      } else {
        this.toggleFeeSection(true);
      }
    }

    this.bookingFeeTarget.innerHTML = toMoneyString(bookingFeeAsCents / 100, currencyPrefix, currencySuffix);
    this.gatewayFeeTarget.innerHTML = toMoneyString(gatewayFeeAsCents / 100, currencyPrefix, currencySuffix);
    this.customerFeePayableTarget.innerHTML = toMoneyString(customerFeePayableValueAsCents / 100, currencyPrefix, currencySuffix);
    this.organiserFeeReceivableTarget.innerHTML = toMoneyString(organiserFeeReceivableValueAsCents / 100, currencyPrefix, currencySuffix);
  }

  get isOnlineStreamingEvent() {
    return this.data.get('is-event-online-streaming') === "true";
  }

  get isFeesOverridden() {
    return this.data.get('is-fees-overridden') === "true";
  }

  get currentBookingFee() {
    return Number(this.data.get('current-booking-fee'));
  }

  disableFeeCustomisation() {
    this.isCustomerChargedForGatewayFeeTarget.disabled = true;
    this.isCustomerChargedForBookingFeeTarget.disabled = true;
    this.isCustomerChargedForBookingFeeTarget.checked = true;
    this.bookingFeePercentageInfoContainerTarget.style.display = 'none';
  }

  toggleFeeSection(shouldShow) {
    if (shouldShow) {
      this.feeSectionTarget.style.display = "block";
    } else {
      this.feeSectionTarget.style.display = "none";
    }
  }

  getMoneyAsCents(value) {
    return Math.floor(Number(value) * 100);
  }

  getCurrentPriceAsCents() {
    return this.getMoneyAsCents(this.priceInputTarget.value);
  }

  onPriceInputChange() {
    this.priceAsCents = this.getCurrentPriceAsCents();
    this.updateOutput();
  }

  onCustomerChargedForBookingFeeChange(e) {
    this.isCustomerChargedForBookingFee = e.target.checked;
    this.updateOutput();
  }

  onCustomerChargedForGatewayFeeChange(e) {
    this.isCustomerChargedForGatewayFee = e.target.checked;
    this.updateOutput();
  }

  connect() {
    if (this.isFeesOverridden) this.disableFeeCustomisation();

    this.bookingFeePercentage = this.data.get('booking-fee-percentage');
    this.gatewayFeePercentage = this.data.get('gateway-fee-percentage');
    this.bookingFeeAbsoluteValue = 0;
    this.isCustomerChargedForBookingFee = this.isCustomerChargedForBookingFeeTarget.checked;
    this.isCustomerChargedForGatewayFee = this.isCustomerChargedForGatewayFeeTarget.checked;
    this.priceAsCents = this.getCurrentPriceAsCents();

    this.updateOutput();
  }

}
